<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Permissions</b-card-title>
      <b-card-sub-title class="text-capitalize">Role: {{userData.role.name}}</b-card-sub-title>
    </b-card-body>
    <b-table
      striped
      responsive
      :items="permissionData"
      class="mb-0"
      style="max-height: 40vh;"
      sticky-header="true"
      :no-border-collapse="true"
    >

      <template #cell(model)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          :checked="data.value"
        />
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    permissionData: {
      type: Array,
      required: true,
    }
  },
}
</script>

<style>

</style>
